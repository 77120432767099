import {api} from 'boot/axios';
import {AxiosResponse} from 'axios';
import {Page} from 'src/models/cms';

export default {
  getPage: function (slug): Promise<AxiosResponse<Page>> {
    return api.get(`cms/pages/${slug}/`)
  },

  getTestimonials: function () {
    return api.get('cms/testimonials/')
  },

  getLinkTreeElements: function () {
    return api.get('cms/link-tree-elements/')
  }
}
