<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <div class="flex q-gutter-sm items-center">
          <q-input
            :model-value="buildUrl(url, 'linkShare')" class="col" dense label="Inserat URL" outlined readonly
            @click="$event.target.select()"/>
          <div>
            <q-btn dense flat no-caps @click="copy(buildUrl(url, 'linkShare'))">Kopieren</q-btn>
          </div>
        </div>
      </q-card-section>

      <q-card-section class="flex justify-around">
        <q-btn :href="shareURL.whatsapp" flat icon="fab fa-whatsapp" round size="lg" target="_blank"/>
        <q-btn :href="shareURL.facebook" flat icon="fab fa-facebook" round size="lg" target="_blank"/>
        <q-btn :href="shareURL.twitter" flat icon="fab fa-twitter" round size="lg" target="_blank"/>
        <q-btn :href="shareURL.reddit" flat icon="fab fa-reddit" round size="lg" target="_blank"/>
        <q-btn :href="shareURL.linkedin" flat icon="fab fa-linkedin" round size="lg" target="_blank"/>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn label="OK" outline @click="onOKClick"/>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import {copyToClipboard, Notify, useDialogPluginComponent} from 'quasar'
import {reactive, toRefs} from 'vue';

const props = defineProps({
  url: {
    required: true
  },
  text: {
    default: 'CamperTrader.de'
  },
  mtmSource: {
    type: String,
    default: undefined
  }
})

defineEmits([
  // REQUIRED; need to specify some events that your
  // component will emit through useDialogPluginComponent()
  ...useDialogPluginComponent.emits
])

const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()
// dialogRef      - Vue ref to be applied to QDialog
// onDialogHide   - Function to be used as handler for @hide on QDialog
// onDialogOK     - Function to call to settle dialog with "ok" outcome
//                    example: onDialogOK() - no payload
//                    example: onDialogOK({ /*...*/ }) - with payload
// onDialogCancel - Function to call to settle dialog with "cancel" outcome


const buildUrl = function (url, refName = undefined) {
  const matomoParams = refName ? `?mtm_campaign=ShareButtonDetailPage%26mtm_kwd=${refName}&mtm_source=${props.mtmSource}` : ''
  return encodeURI(url) + matomoParams
}

const propsRef = toRefs(props)
const shareUrl = propsRef.url.value

let msg = encodeURIComponent(propsRef.text.value)

const shareURL = reactive({
  facebook: `https://www.facebook.com/share.php?u=${buildUrl(shareUrl, 'facebook')}`,
  twitter: `https://twitter.com/share?url=${buildUrl(shareUrl, 'twitter')}&text=${msg}&hashtags=campertrader,vanlife`,
  linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${buildUrl(shareUrl, 'linkedin')}`,
  reddit: `https://www.reddit.com/submit?url=${buildUrl(shareUrl, 'reddit')}&title=${msg}`,
  whatsapp: `https://api.whatsapp.com/send?text=${msg}: ${buildUrl(shareUrl, 'whatsapp')}`,
})

const copy = function (val) {
  copyToClipboard(val)
    .then(() => {
      Notify.create({
        message: 'In die Zwischenablage kopiert.'
      })
    })
    .catch(() => {
      Notify.create({
        type: 'negative',
        message: 'Fehler beim kopieren in die Zwischenablage. Bitte kopieren die URL manuell.'
      })
    })
}

// this is part of our example (so not required)
function onOKClick() {
  // on OK, it is REQUIRED to
  // call onDialogOK (with optional payload)
  onDialogOK()
  // or with payload: onDialogOK({ ... })
  // ...and it will also hide the dialog automatically
}
</script>

<style lang="scss" scoped>

</style>
