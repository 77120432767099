import {CategoryItem} from 'src/apps/search/types';
import {useMainStore} from 'stores/main';
import {useMarketStore} from 'src/apps/market/store';


export function useSearchTypeCategories() {
  const mainStore = useMainStore()
  const marketStore = useMarketStore()

  const catVehicle: Array<CategoryItem> = [
    {
      label: 'Vom Händler',
      name: 'business',
      image: require('src/assets/category_select_icons/icon_haendler.svg'),
      filterData: {
        is_business: true
      }
    },
    {
      label: 'Autark',
      name: 'autark',
      image: require('src/assets/category_select_icons/icon_autark.svg'),
      filterData: {vehicle__extras__slug: ['battery', 'solar', 'water_tank', 'toilet']}
    },
    {
      label: 'Winter',
      name: 'winterSport',
      image: require('src/assets/category_select_icons/icon_winter.svg'),
      filterData: {vehicle__extras__slug: ['heater', 'hot_water',]}
    },
    {
      label: 'Familie',
      name: 'family',
      image: require('src/assets/category_select_icons/icon_family.svg'),
      filterData: {
        vehicle__seats_min: 3,
        vehicle__beds_min: 3,
      }
    },
    {
      label: '4x4',
      name: '4x4',
      image: require('src/assets/category_select_icons/icon_offroad.svg'),
      filterData: {
        vehicle__all_wheel_drive: true,
        vehicle__car_type: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Low Budget',
      name: 'low_budget',
      image: require('src/assets/category_select_icons/icon_low_budget.svg'),
      filterData: {
        price_max: 30_000,
      }
    },
    {
      label: 'Selbstausbauten',
      name: 'ownBuild',
      image: require('assets/category_select_icons/icon_diy_ausbau.svg'),
      filterData: {vehicle__build_type: 'own'},
    },
    {
      label: 'Remote Work',
      name: 'remoteWork',
      image: require('src/assets/category_select_icons/icon_remote.svg'),
      filterData: {
        vehicle__extras__slug: ['battery', 'inverter'],
      }
    },
    {
      label: 'Luxus',
      name: 'luxurious',
      image: require('src/assets/category_select_icons/icon_luxus.svg'),
      filterData: {
        price_min: 100_000,
      }
    },
    {
      label: 'Campervan',
      name: 'campervan',
      image: require('assets/category_select_icons/icon_camper.svg'),
      filterData: {
        vehicle__car_type: 'campervan',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Tiny Camper',
      name: 'tiny_camper',
      image: require('assets/category_select_icons/icon_tiny_camper.svg'),
      filterData: {
        vehicle__car_type: 'tiny_camper',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Alkoven',
      name: 'alkoven',
      image: require('assets/category_select_icons/icon_wohnmobil_alkoven.svg'),
      filterData: {
        vehicle__car_type: 'alkoven',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Integrierter',
      name: 'integrierter',
      image: require('assets/category_select_icons/icon_wohnmobil_integriert.svg'),
      filterData: {
        vehicle__car_type: 'integrierter',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Teilintegrierter',
      name: 'teilintegrierter',
      image: require('assets/category_select_icons/icon_wohnmobil.svg'),
      filterData: {
        vehicle__car_type: 'teilintegrierter',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Wohnwagen',
      name: 'wohnwagen',
      image: require('assets/category_select_icons/icon_wohnwagen.svg'),
      filterData: {
        vehicle__car_type: 'wohnwagen',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Oldtimer',
      name: 'oldtimer',
      image: require('assets/category_select_icons/icon_oldtimer.svg'),
      filterData: {
        vehicle__is_oldtimer: true,
        vehicle__car_type: undefined,
        vehicle__all_wheel_drive: undefined
      }
    },
    {
      label: 'Liner',
      name: 'liner',
      image: require('assets/category_select_icons/icon_liner.svg'),
      filterData: {
        vehicle__car_type: 'liner',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
    {
      label: 'Besondere',
      name: 'other',
      icon: 'fas fa-vial',
      filterData: {
        vehicle__car_type: 'other',
        vehicle__all_wheel_drive: undefined,
        vehicle__is_oldtimer: undefined
      }
    },
  ]

  const catMarket: Array<CategoryItem> = [
    {label: 'Gesuche', name: 'requests', filterData: {item_type: 'request'}},
    {label: 'Zu Verschenken', name: 'give_away', filterData: {offer_type: 'give_away'}},
  ]

  const categories = {
    vehicle: catVehicle,
    dealer: () => {
      const services = mainStore.businessServicesAvailable.map(item => ({
        label: item.name,
        name: item.name.toLowerCase().replace(' ', '_'),  // replace spaces with underscores for the name
        filterData: {services: [item.id]}
      }));

      return [
        ...services
      ]
    },
    market: () => {
      marketStore.fetchAvailableCategories().catch(console.error)
      const categories = marketStore.itemAvailableCategories?.map(item => ({
        label: item.name,
        name: item.name.toLowerCase().replace(' ', '_'),
        filterData: {categories: item.id}
      })) || []

      return [
        ...catMarket,
        ...categories
      ]
    },
  }

  function getSearchTypeCategories(name: string) {
    if (typeof categories[name] === 'function') {
      return categories[name]();
    }
    return categories[name];
  }

  function getSearchTypeCategory(name: string, catName: string) {
    const cats = getSearchTypeCategories(name)
    if (!cats) {
      console.debug('No categories found for search type', name)
      return {}
    }
    try {
      return cats.find(cat => cat.name === catName) ?? {}
    } catch (err) {
      console.error('Error getting category', cats, err)
      return {}
    }
  }

  return {
    getSearchTypeCategories,
    getSearchTypeCategory,
  }
}
