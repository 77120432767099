export default [
  {
    path: '',
    component: () => import('layouts/BackBtnLayout.vue'),
    children: [

      {
        path: '/payment/checkout/:client_secret/',
        component: () => import('src/apps/payment/pages/CheckoutPage.vue'),
        name: 'checkout',
      },

      // {
      //   path: '/checkout/:client_secret/',
      //   component: () => import('src/apps/payment/pages/CheckoutSessionPage.vue'),
      //   name: 'checkout',
      //   meta: {}
      // },
    ]
  },
  {
    path: '',
    component: () => import('layouts/AppMainLayout.vue'),
    children: [
      {
        path: '/payment/processing/:status?/',
        component: () => import('src/apps/payment/pages/PaymentProcessing.vue'),
        name: 'payment-processing',
      },
    ],
    meta: {noFooter: true, noHeader: true, noFooterBar: true, noSiteLoading: true},
  }
]
