import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "flex column full-height" }
const _hoisted_2 = {
  class: "relative-position",
  style: {"flex-grow":"1"}
}
const _hoisted_3 = { class: "text-center absolute-center full-width" }
const _hoisted_4 = { class: "flex column full-width q-gutter-y-md items-center" }
const _hoisted_5 = {
  class: "q-pa-md bg-secondary text-white relative-position",
  style: {"width":"150px","border-radius":"50%","aspect-ratio":"1"}
}
const _hoisted_6 = { class: "text-h3 font-inter" }
const _hoisted_7 = {
  class: "q-py-md",
  style: {"flex-grow":"0"}
}
const _hoisted_8 = { class: "flex column q-gutter-y-md" }

import {useDialogPluginComponent, useQuasar} from 'quasar'
import {computed, onMounted, ref, toRefs} from 'vue';
import {CapacitorPreferences, CapacitorPushNotifications, CapacitorTrackingTransparency} from 'boot/inject-capacitor';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppStartScreen',
  props: {
  screens: {
    type: Array as () => Array<'notification' | 'tracking'>,
    default: () => []
  }
},
  emits: [
  ...useDialogPluginComponent.emits
],
  setup(__props) {

const screenData = [
  {
    name: 'tracking',
    title: 'Erlaube Tracking',
    description: 'Damit können wir die bessere Produkte vorschlagen und dir eine bessere App Erfahrung bieten.',
    icon: 'fas fa-ranking-star',
    // cancel: denyTrackingPermission,
    ok: requestTrackingPermission,
    okLabel: 'Weiter'
  },
  {
    name: 'notification',
    title: 'Benachrichtigungen',
    description: 'Wir senden dir Push Benachrichtigungen, wenn du neue Nachrichten hast ' +
      'oder bei wichtigen Ereignissen.',
    icon: 'fas fa-bell',
    cancel: denyNotification,
    ok: allowNotification,
  },
]
const props = __props

const screen = computed(() => screenData.find(s => s.name === currentScreen.value))

const $q = useQuasar()
const loading = ref(false)
const currentScreen = ref<'notification' | 'tracking'>()
const {screens} = toRefs(props)
const _screens = ref<Array<'notification' | 'tracking'>>([...screens.value])



const {dialogRef, onDialogHide, onDialogOK} = useDialogPluginComponent()

function next() {
  if (_screens.value.length === 0) {
    onDialogOK()
    return
  }

  const __screen = _screens.value.shift()
  if (!__screen) {
    onDialogHide()
    return
  }
  currentScreen.value = __screen
}

const registerNotifications = async () => {
  let permStatus = await CapacitorPushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await CapacitorPushNotifications.requestPermissions();
  }

  if (permStatus.receive === 'granted') {
    await CapacitorPushNotifications.register();
  }

  return Promise.resolve(permStatus)
}

onMounted(() => {
  currentScreen.value = _screens.value.shift()
})

async function allowNotification() {
  loading.value = true
  try {
    await registerNotifications()
  } catch (err) {
    loading.value = false
    $q.notify({
      type: 'negative',
      message: 'Fehler beim Anfragen der Benachrichtigungsrechte.',
      caption: 'Bitte versuche es später erneut.',
      actions: [{icon: 'close', 'aria-label': 'Schließen', round: true, dense: true, color: 'white'}]
    })
    console.error(err)
    return
  }

  loading.value = false
  await CapacitorPreferences.set({key: 'permission_notification_prompted', value: 'true'})
  next()
}

async function denyNotification() {
  await CapacitorPreferences.set({key: 'permission_notification_prompted', value: 'true'})
  next()
}


async function requestTrackingPermission() {
  try {
    await CapacitorTrackingTransparency.requestPermission()
    await CapacitorPreferences.set({key: 'permission_tracking_prompted', value: 'true'})
  } catch (error) {
    console.error(error)
    return error
  }
  next()
}

// function denyTrackingPermission() {
//   CapacitorPreferences.set({key: 'permission_tracking_prompted', value: 'true'})
//   next()
// }




return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    ref_key: "dialogRef",
    ref: dialogRef,
    maximized: "",
    persistent: "",
    onHide: _unref(onDialogHide)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { class: "q-dialog-plugin" }, {
        default: _withCtx(() => [
          (screen.value)
            ? (_openBlock(), _createBlock(_component_q_card_section, {
                key: 0,
                class: "full-height"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("div", null, [
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode(_component_q_icon, {
                                class: "absolute-center",
                                size: "5rem",
                                name: screen.value.icon
                              }, null, 8, ["name"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_6, _toDisplayString(screen.value.title), 1),
                          _createElementVNode("div", null, _toDisplayString(screen.value.description), 1)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_q_btn, {
                          color: "secondary",
                          loading: loading.value,
                          size: "lg",
                          rounded: "",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (screen.value.ok()))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(screen.value.okLabel ?? 'Erlauben'), 1)
                          ]),
                          _: 1
                        }, 8, ["loading"]),
                        (screen.value.cancel)
                          ? (_openBlock(), _createBlock(_component_q_btn, {
                              key: 0,
                              flat: "",
                              disable: loading.value,
                              onClick: _cache[1] || (_cache[1] = ($event: any) => {screen.value.cancel();})
                            }, {
                              default: _withCtx(() => _cache[2] || (_cache[2] = [
                                _createTextVNode("Später erinnern")
                              ])),
                              _: 1
                            }, 8, ["disable"]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onHide"]))
}
}

})