import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex column",
  style: {"row-gap":"12px"}
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  style: {"height":"168px"},
  class: "relative-position"
}
const _hoisted_4 = { class: "absolute-center" }

import {CapacitorBrowser} from 'boot/inject-capacitor';
import {useQuasar} from 'quasar';
import {randomString} from 'src/etc/helper';
import {onMounted, ref} from 'vue';

interface Button {
  name: string,
  icon?: string,
  text: string,
  class?: string | object,
  style?: object | string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'SocialLogin',
  setup(__props) {

const loadingButton = ref({
  google: false,
  facebook: false,
  apple: false,
})

function getRedirectUrl(providerName) {
  try {
    if (process.env.DEV && process.env.SOCIAL_AUTH_CALLBACK_DEBUG_NGROK) {
      return `${process.env.SOCIAL_AUTH_CALLBACK_DEBUG_NGROK}/v1/${$q.platform.is.nativeMobile ? 'app/' : ''}redirect/oauth/${providerName}/`
    }
    return `${process.env.API_BASE}${$q.platform.is.nativeMobile ? 'app/' : ''}redirect/oauth/${providerName}/`

  } catch (e) {
    console.error(e)
  }
}

const baseAuthUrls = {
  'google': 'https://accounts.google.com/o/oauth2/v2/auth',
  'facebook': 'https://www.facebook.com/v19.0/dialog/oauth',
  'apple': 'https://appleid.apple.com/auth/authorize',
}

const state = randomString(16)
const nonce = randomString(16)
const $q = useQuasar()


const clientIDs = {
  google: (() => {
    try {
      return process.env.GOOGLE_CLIENT_ID
    } catch (e) {
      return ''
    }
  })(),
  facebook: (() => {
    try {
      return process.env.FACEBOOK_CLIENT_ID
    } catch (e) {
      return ''
    }
  })(),
  apple: (() => {
    try {
      return process.env.APPLE_CLIENT_ID
    } catch (e) {
      return ''
    }
  })()
}

const authParams = {
  google: {
    prompt: 'consent',
    redirect_uri: getRedirectUrl('google'),
    response_type: 'code',
    client_id: clientIDs.google,
    scope: 'openid email profile',
    access_type: 'offline',
  },
  facebook: {
    client_id: clientIDs.facebook,
    redirect_uri: getRedirectUrl('facebook'),
    state: `{st=${state},ds=${nonce}`,
    scope: 'email,public_profile',
  },
  apple: {
    client_id: clientIDs.apple,
    redirect_uri: getRedirectUrl('apple'),
    response_type: 'code',
    response_mode: 'form_post',
    scope: 'name email',
    state: state,
    nonce: nonce,
  }
}

async function getUrlWithParams(url: string, params: object) {
  const _url = new URL(url)
  let searchParams = new URLSearchParams(_url.search);
  Object.keys(params).forEach(key => {
    searchParams.append(key, params[key]);
  });
  _url.search = searchParams.toString();
  return _url.toString()
}


async function openUrl(providerName: string) {
  loadingButton.value[providerName] = true
  const url = baseAuthUrls[providerName]
  const params = authParams[providerName]
  const authUrl = await getUrlWithParams(url, params)

  if ($q.platform.is.nativeMobile) {
    // If it's running on a mobile platform, open the URL in the in-app browser
    CapacitorBrowser.open({url: authUrl}).then(() => {
      loadingButton.value[providerName] = false
    })
  } else {
    // If it's running on the web, return the URL as is
    loadingButton.value[providerName] = false
    window.location.href = authUrl
  }
}

const buttons = ref<Array<Button>>()

onMounted(() => {
  buttons.value = []

  buttons.value.push({
    name: 'google',
    icon: 'fab fa-google colored',
    text: 'Weiter mit Google',
  })

  buttons.value.push({
    name: 'apple',
    icon: 'fab fa-apple',
    text: 'Weiter mit Apple',
    style: {
      backgroundColor: '#000000'
    },
    class: 'text-white'
  })

  buttons.value.push({
    name: 'facebook',
    icon: 'fab fa-facebook',
    text: 'Weiter mit Facebook',
    style: {
      backgroundColor: '#1877F2'
    },
    class: 'text-white'
  })

  // Reorder based on platform
  if ($q.platform.is.ios) {
    // Move Apple login to the top for iOS
    buttons.value = [
      buttons.value[1], // Apple button
      buttons.value[2], // Facebook button
      buttons.value[0], // Google button
    ];
  } else if ($q.platform.is.android) {
    // Move Google login to the top for Android
    buttons.value = [
      buttons.value[0], // Google button
      buttons.value[2], // Facebook button
      buttons.value[1], // Apple button
    ];
  }
})

return (_ctx: any,_cache: any) => {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_spinner = _resolveComponent("q-spinner")!

  return (buttons.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(buttons.value, (button, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createVNode(_component_q_btn, {
              loading: loadingButton.value[button.name],
              class: _normalizeClass(["full-width", button.class]),
              style: _normalizeStyle([{"padding":"12px 0"}, button.style]),
              rounded: "",
              "no-caps": "",
              onClick: _withModifiers(($event: any) => (openUrl(button.name)), ["prevent"])
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_icon, {
                  name: button.icon
                }, null, 8, ["name"]),
                _createTextVNode("  " + _toDisplayString(button.text), 1)
              ]),
              _: 2
            }, 1032, ["loading", "class", "style", "onClick"])
          ]))
        }), 128))
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_q_spinner, { size: "md" })
          ])
        ])
      ]))
}
}

})