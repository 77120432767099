import {api} from 'src/boot/axios';

export default {
  getPersonalSubscriptionMetaList: function () {
    return api.get('subscription/plans/personal/')
  },

  getCommercialSubscriptionMetaList: function () {
    return api.get('subscription/plans/commercial/')
  },

  getPlan: function (planId) {
    return api.get(`payment/plans/${planId}/`)
  },

  createSubscription: function (data) {
    return api.post('subscription/create/', data)
  },

  cancelSubscription: function (force = false) {
    return api.post('subscription/cancel/', {force: force})
  },

  reactivateSubscription: function () {
    return api.post('subscription/reactivate/')
  },

  getSubscription: function (refresh = false) {
    return api.get('subscription/status/', {params: {refresh: refresh}})
  },

  getSubscriptionByClientSecret: function (clientSecret) {
    return api.get(`subscription/invoice/${clientSecret}/`)
  },

  getAccountQuota() {
    return api.get('subscription/quota/')
  },

  updatePaymentMethod(paymentMethodId: string) {
    return api.post('subscription/update-payment-method/', {payment_method_id: paymentMethodId})
  },
}
