import {PiniaPluginContext} from 'pinia';
import {getStorageData, setStorageData} from 'src/etc/helper';


type Store = PiniaPluginContext['store'];
type PartialState = Partial<Store['$state']>;
type RestoredCallback = (store: Store) => void;

// Interface for enabling persistence and configuring options like include/exclude keys
export interface PersistOptions {
  enabled: true;            // Enables persistence if true
  include?: string[];        // List of state keys to include in persistence (optional)
  exclude?: string[];        // List of state keys to exclude from persistence (optional)
  onRestored?: RestoredCallback; // Callback after state is restored (optional)
}

// Interface defining the persistence rules
export interface PersistenceRules {
  include: string[];         // List of included state keys
  exclude: string[];         // List of excluded state keys
}

// Extending Pinia's base store options and custom properties to include persistence logic
declare module 'pinia' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface DefineStoreOptionsBase<S, Store> {
    persist?: PersistOptions;
  }

  export interface PiniaCustomProperties {
    restored: Promise<void>; // Promise to track the restoration status
  }
}


// Function to update storage with current state
const updateStorage = async (store: Store, rules: PersistenceRules): Promise<void> => {
  const storeKey = store.$id;

  // Determine which keys to include/exclude when storing state
  const paths = rules.include.length
    ? rules.include
    : Object.keys(store.$state).filter((key) => !rules.exclude.includes(key));

  // Construct the partial state to store based on the include/exclude rules
  const partialState = paths.reduce((acc, key) => {
    acc[key] = store.$state[key];
    return acc;
  }, {} as PartialState);

  // Store the relevant state to the selected storage (native or web)
  await setStorageData(storeKey, partialState);
};

// Function to restore state from storage and subscribe to changes
const restoreState = async (store: Store, storeKey: string, rules: PersistenceRules, onRestored?: RestoredCallback): Promise<void> => {
  const storedData = await getStorageData(storeKey);

  // If there's stored data, patch it into the current store state
  if (storedData) {
    store.$patch(storedData);
  }

  // Subscribe to store changes and update storage whenever the store changes
  store.$subscribe(() => {
    updateStorage(store, rules);
  });

  // If the onRestored callback is provided, invoke it after state restoration
  if (onRestored) {
    onRestored(store);
  }
};

// The main plugin function that integrates with Pinia
export const piniaCapacitorPersist = ({options, store}: PiniaPluginContext): void => {
  // If persistence is not enabled, do nothing
  if (options.persist?.enabled !== true) return;

  // Define persistence rules based on the options (include/exclude)
  const rules: PersistenceRules = {
    include: options.persist.include || [],
    exclude: options.persist.exclude || [],
  };

  const storeKey = store.$id; // Use the store's ID as the key for storage

  // Set up restoration logic and subscribe to store changes
  store.restored = restoreState(store, storeKey, rules, options.persist.onRestored);
};
