import {defineStore} from 'pinia';
import Auth from 'src/api/auth';
import {Cookies, Loading, Platform} from 'quasar';
import Messages from 'src/api/messages';
import {AccountDetail} from 'src/models/user';
import Adverts from 'src/api/adverts';
import {useSSRContext} from 'vue';
import Core from 'src/api/core';
import Vehicles from 'src/api/vehicles';
import {ChatModel, MessageModel} from 'src/models/message.model';
import Cms from 'src/api/cms';
import Business from 'src/api/business';
import BusinessApi from 'src/api/business';
import axios from 'axios';
import Widgets from 'src/api/widgets';
import {SiteConfigModel} from 'src/models/cms';
import Subscription from 'src/api/subscription';
import Payment from 'src/api/payment';
import {useSubscriptionStore} from 'src/apps/subscription/store';
import {axiosConsoleError} from 'src/apps/core/utils';
import {CapacitorPreferences, CapacitorPushNotifications} from 'boot/inject-capacitor';
import {BusinessService} from 'src/models/business.model';
import {reloadApp} from 'src/etc/helper';

let siteLoadingTimeout

export const useMainStore = defineStore('main', {
  state: () => ({
    siteLoading: true,
    siteInitLoading: true,

    deviceId: undefined as undefined | string,
    testFlag: undefined as undefined | string,

    isFirstLoad: true,
    accountDetail: {} as AccountDetail | undefined,
    accountUsage: {} as object,
    loggedInState: false,
    messagesUnread: 0,
    adverts: {},
    siteConfig: {} as SiteConfigModel,

    userProfiles: {},
    showCookieBanner: false,
    vehicleExtras: [] as Array<any>,
    filter: {vehicle__extras__slug: []} as any,
    editAdverts: {},
    indexAdverts: {},
    userChats: [],
    userChatDetails: {} as ChatModel,
    userActiveChatMessages: [] as Array<MessageModel>,
    userActiveChat: undefined as ChatModel | undefined,
    userChatLoading: false,
    faqs: [],
    businessPublicProfiles: {},
    pages: {},
    businessProfiles: {},
    businessAccount: undefined,
    businessServices: [] as Array<BusinessService>,
    businessServicesAvailable: [] as Array<BusinessService>,
    businessFirstPageAdverts: {},
    isErrorPage: undefined as undefined | string,
    scrollPositions: {},
    cities: [],
    locationByIp: {} as any,
    widgetData: {},
    filterMapping: undefined,
    signupFormFields: undefined,
    businessRegisterFormFields: undefined,
    subscriptionMetaPersonalPlans: [],
    subscriptionMetaCommercialPlans: [],
    priceList: {},
    commercialProducts: [],
    personalProducts: [],
    accountOpenInvoices: [],
    invoice: {},
    signupVerifyEmail: undefined as string | undefined,
    googleReviewsResponse: {} as any,
    networkStatus: undefined as undefined | any,
    serverNetworkError: false
  }),

  getters: {
    isLoggedIn: function (state) {
      return state.loggedInState
    },
  },

  actions: {
    getGoogleReviews: async function () {
      if (Object.keys(this.googleReviewsResponse).length) {
        return this.googleReviewsResponse
      }
      try {
        const resp = await Core.fetchGoogleReviews()
        this.googleReviewsResponse = resp.data
        return resp.data

      } catch (e) {
        console.error(e)
        return {}
      }
    },
    fetchCommercialProducts: function () {
      return Payment.getCommercialProducts().then(resp => {
        this.commercialProducts = resp.data
      }).catch(error => {
        console.error(error)
        return error
      })
    },

    fetchInvoice: function (clientSecret: string) {
      return Payment.getInvoice(clientSecret).then(resp => {
        this.invoice[clientSecret] = resp.data
        return resp
      }).catch(err => {
        console.error(err)
        return err
      })
    },

    fetchAccountOpenInvoices: function () {
      return Payment.getAccountOpenInvoices().then(resp => {
        this.accountOpenInvoices = resp.data
      }).catch(err => {
        console.error(err)
        return err
      })
    },

    fetchPersonalProducts: function () {
      return Payment.getPersonalProducts().then(resp => {
        this.personalProducts = resp.data
      }).catch(error => {
        console.error(error)
        return error
      })
    },

    fetchPersonalSubscriptionPlans: function () {
      return Subscription.getPersonalSubscriptionMetaList().then(resp => {
        this.subscriptionMetaPersonalPlans = resp.data
        return resp
      }).catch(error => {
        console.error(error)
        return error
      })
    },

    fetchCommercialSubscriptionPlans: function () {
      return Subscription.getCommercialSubscriptionMetaList().then(resp => {
        this.subscriptionMetaCommercialPlans = resp.data
        return resp
      }).catch(error => {
        console.error(error)
        return error
      })
    },

    fetchSubscriptionPlans: function () {
      return Promise.all([
        this.fetchPersonalSubscriptionPlans(),
        this.fetchCommercialSubscriptionPlans(),
      ])
    },

    getPrice: async function (lookupKey) {
      // Check if the key exists in the priceList
      if (this.priceList[lookupKey]) {
        return this.priceList[lookupKey];
      }
      try {
        // If the key does not exist, make a request
        const resp = await Payment.getPrice(lookupKey);
        // Store the response in priceList
        this.priceList[lookupKey] = resp.data;
        return resp.data;
      } catch (error) {
        // Handle any errors
        console.error(error);
        return error;
      }
    },

    fetchFilterMapping: function () {
      return Adverts.filterMapping().then(resp => {
        this.filterMapping = resp.data
        return resp
      }).catch(err => {
        console.error(err)
        return err
      })
    },

    fetchRegisterBusinessFormFields: function () {
      return Business.fetchBusinessRegisterFormOptions().then(resp => {
        this.businessRegisterFormFields = resp.data.fields
      }).catch(error => {
        console.error(error)
        return error
      })
    },

    fetchSignupFormFields: function () {
      return Business.fetchSignupFormOptions().then(resp => {
        this.signupFormFields = resp.data.actions.POST
      }).catch(error => {
        console.error(error)
        return error
      })
    },

    fetchWidgetData: function (uuid) {
      return Widgets.fetchPublicAdvertListWidget(uuid).then(resp => {
        this.widgetData[uuid] = resp.data
        return resp
      }).catch(error => {
        console.error(error)
        return error
      })
    },

    getLocationByIp: function () {
      return axios.get('https://api.ipify.org?format=json').then(response => {
        const ip = response.data.ip;
        return axios.get(`https://ipapi.co/${ip}/json/`).catch(error => {
          axiosConsoleError(error)
          return error
        })
      }).then(resp => {
        if (resp.data?.country == 'DE') {
          const lat = resp.data.latitude
          const lng = resp.data.longitude

          const url = `https://nominatim.openstreetmap.org/search?q=${lat},${lng}&format=json&polygon=1&addressdetails=1&limit=1&accept-language=de`

          return axios.get(url).then(resp => {
            if (resp.data?.length) {
              this.locationByIp = {
                city: resp.data[0].address.city || resp.data[0].address.town || resp.data[0].address.village,
                region: resp.data[0].address.state,
                country: resp.data[0].address?.country,
              }
            }
          })
        }
      }).catch(error => {
        axiosConsoleError(error)
      })
    },

    fetchCities: function () {
      return Core.fetchCities().then(resp => {
        this.cities = resp.data
        return resp
      }).catch(error => {
        axiosConsoleError(error)
        return error
      })
    },

    fetchBusinessFirstPageAdverts: function (slug: string) {
      return Business.fetchBusinessPublicAdvertList(slug).then(resp => {
        this.businessFirstPageAdverts[slug] = resp.data.results
        return resp
      }).catch(error => {
        console.error(error)
        return error
      })
    },
    fetchBusinessServices: function (force = false) {
      if (this.businessServices.length && !force) {
        return Promise.resolve({data: this.businessServices})
      }
      return Business.fetchBusinessServices().then(resp => {
        this.businessServices = resp.data
        return resp
      }).catch(error => {
        console.error(error)
        return error
      })
    },
    fetchBusinessServicesAvailable: function (force = false) {
      if (this.businessServicesAvailable.length && !force) {
        return Promise.resolve({data: this.businessServicesAvailable})
      }
      return Business.fetchBusinessServicesAvailable().then(resp => {
        this.businessServicesAvailable = resp.data
        return resp
      }).catch(error => {
        console.error(error)
        return error
      })
    },
    fetchBusinessPublicAccount: function (slug) {
      return Business.fetchBusinessPublicDetails(slug).then(resp => {
        this.businessPublicProfiles[resp.data.slug] = resp.data
        return resp
      })
    },

    fetchBusiness: function (slug) {
      return BusinessApi.fetchBusiness(slug).then(resp => {
        this.businessProfiles[slug] = resp.data
        return resp
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    getStoredBusiness: async function (slug) {
      if (!this.businessAccount) {
        try {
          const resp = await this.fetchBusiness(slug)
          this.businessAccount = resp.data
        } catch (e) {
          console.error(e)
          return
        }
      }
      return this.businessAccount
    },

    fetchPage: function (slug) {
      return Cms.getPage(slug).then(resp => {
        this.pages[slug] = resp.data
        return resp
      })
    },

    fetchMessengerUserChats: function () {
      return Messages.getUserChats().then(resp => {
        this.userChats = resp.data
        return resp
      }).catch(error => {
        console.error(error)
        return Promise.reject(error)
      })
    },

    fetchChat: function (chatUuid) {
      return Messages.getChat(chatUuid).then(resp => {
        this.userChatDetails[chatUuid] = resp.data
        return resp
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    fetchFAQs: function () {
      return Core.getFAQs().then(resp => {
        this.faqs = resp.data
        return resp
      }).catch(error => {
        console.error(error)
        return Promise.reject(error)
      })
    },

    setActiveChat: function (chat) {
      this.userActiveChat = chat
      if (chat?.uuid) {
        Messages.markReadMessage(chat.uuid).then((resp) => {
          chat.unread_num = 0
          return resp
        }).catch(err => {
          console.error(err)
        })
      }
    },

    setActiveAccount: function (slug) {
      return Core.setActiveBusinessAccount(slug).then(async () => {
        await this.fetchAccountDetails()
      })
    },

    fetchActiveChatMessages: function (chatUuid, showLoading = false) {
      this.userChatLoading = showLoading
      return Messages.getMessages(chatUuid)
        .then((resp) => {
          this.userActiveChatMessages = resp.data
        }).finally(() => {
          this.userChatLoading = false
        }).catch(error => {
          console.error(error)
          return error
        })
    },

    setFilter: function (data) {
      this.filter = data
    },

    fetchAccountDetails: function () {
      return Auth.getAccountDetails().then(resp => {
        this.accountDetail = resp.data
        this.loggedInState = true
        this.fetchUnreadMessagesCount()
        return resp
      }).catch(err => {
        console.error('error fetchAccountDetails', err)
        return err
      })
    },

    fetchVehicleExtras: function () {
      return Vehicles.getExtrasList().then(resp => {
        this.vehicleExtras = resp.data
        return resp
      }).catch(err => {
        console.error(err)
        return err
      })
    },

    setSiteLoading: function (state: boolean, delay = 0) {
      if (siteLoadingTimeout) clearTimeout(siteLoadingTimeout)

      if (!state || delay === 0) {
        this.siteLoading = state
      } else {
        siteLoadingTimeout = setTimeout(() => {
          this.siteLoading = state
        }, delay)
      }
    },

    fetchSiteConfig: function () {
      return Core.getConfig().then(resp => {
        this.siteConfig = resp.data
        return resp
      }).catch(err => {
        console.error(err)
        return err
      })
    },

    fetchUserProfile: function (uuid) {
      return Auth.getUserInfo(uuid).then(resp => {
        this.userProfiles[uuid] = resp.data
        return resp
      }).catch(error => {
        console.error(error)
        return error
      })
    },

    fetchAdvert: function (uuid) {
      return Adverts.getAdvert(uuid).then(resp => {
        this.adverts[uuid] = resp.data
        return resp
      }).catch(error => {
        console.error(error)
        return Promise.reject(error)
      })
    },

    fetchEditAdvert: function (uuid) {
      return Adverts.getAdvert(uuid).then(resp => {
        this.editAdverts[uuid] = resp.data
        return resp
      }).catch(error => {
        console.error(error)
        return error
      })
    },

    fetchUnreadMessagesCount: function () {
      return Messages.getUnreadMessagesCount().then(resp => {
        this.messagesUnread = resp.data.unread
        return resp
      }).catch(err => {
        console.error(err)
        return err
      })
    },

    login: async function (token) {
      const subStore = useSubscriptionStore()
      const ssrContext = process.env.SERVER ? useSSRContext() : null
      const cookies = process.env.SERVER
        ? Cookies.parseSSR(ssrContext)
        : Cookies // otherwise we're on client
      const platform = process.env.SERVER
        ? Platform.parseSSR(ssrContext)
        : Platform // otherwise we're on client

      if (platform.is.nativeMobile) {
        await CapacitorPreferences.set({
          key: 'token',
          value: token,
        });

        try {
          await CapacitorPushNotifications.register()
        } catch (e) {
          console.error('Could not register device', e)
        }
      } else {
        cookies.set('token', token, {
          path: '/',
          secure: !process.env.DEV,
          sameSite: 'Strict',
        })
      }


      this.loggedInState = true
      try {
        await subStore.fetchAccountSubscription()
      } catch (e) {
        console.error('Could not fetch subscription', e)
      }

      try {
        await subStore.fetchAccountQuota()
      } catch (e) {
        console.error('Could not fetch account quota', e)
      }

      try {
        return await this.fetchAccountDetails()
      } catch (error) {
        console.error('Could not fetch account details', error)
        this.loggedInState = false
        return
      }
    },

    logout: async function (redirect = '/') {
      const ssrContext = process.env.SERVER ? useSSRContext() : null
      const cookies = process.env.SERVER
        ? Cookies.parseSSR(ssrContext)
        : Cookies // otherwise we're on client

      const platform = process.env.SERVER
        ? Platform.parseSSR(ssrContext)
        : Platform // otherwise we're on client

      cookies.remove('token', {path: '/'})
      console.debug('logout: removed token')
      if (platform.is.nativeMobile) {
        try {
          await CapacitorPreferences.remove({key: 'token'})
          console.info('Removed token from Capacitor Preferences')
        } catch (err) {
          console.error('Could not remove Cookie', err)
        }
      }
      console.debug('Removed Cookie "token": logout')

      this.accountDetail = undefined
      this.loggedInState = false
      Loading.show()
      if (platform.is.nativeMobile) {
        await reloadApp()
      } else {
        if (redirect && window) window.location.href = redirect
      }
    }
  },
});
