import {boot} from 'quasar/wrappers';
import VueGtag, {trackRouter} from 'vue-gtag-next';

export default boot(({app, router}) => {
  if (process.env.DEV) {
    console.info('GTag init')
  }

  let GTAG
  try {
    GTAG = process.env.GTAG_ID
  } catch (e) {
    console.error('GTag not found')
    return
  }

  app.use(VueGtag, {
    property: {
      id: GTAG
    },
    isEnabled: false,
  })

  trackRouter(router)
});
