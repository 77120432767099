import {boot} from 'quasar/wrappers';
import Filter from 'src/etc/qutils/filter';

export default boot(({app}) => {
  app.config.globalProperties.$filters = Filter

  if (!process.env.DEV) {
    console.debug = () => {
      return
    }; // Override console.debug to no-op in production
  }
});
