import {api} from 'boot/axios';

export default {
  fetchAdvertListWidgets: function (businessSlug) {
    return api.get(`business/accounts/${businessSlug}/advert-list-widgets/`,)
  },

  createAdvertListWidgets: function (businessSlug, data) {
    return api.post(`business/accounts/${businessSlug}/advert-list-widgets/`, data)
  },

  deleteAdvertListWidgets: function (businessSlug, widgetUuid) {
    return api.delete(`business/accounts/${businessSlug}/advert-list-widgets/${widgetUuid}/`)
  },

  fetchAdvertListWidget: function (businessSlug, widgetUuid) {
    return api.get(`business/accounts/${businessSlug}/advert-list-widgets/${widgetUuid}/`,)
  },

  fetchPublicAdvertListWidget: function (widgetUuid) {
    return api.get(`widgets/advert-list/${widgetUuid}/`,)
  },

  patchAdvertListWidgetAttrs(businessSlug, widgetUuid, data) {
    return api.patch(`business/accounts/${businessSlug}/advert-list-widgets/${widgetUuid}/`, data)
  },

  getAdverts: function (widgetUuid: string, filter: object) {
    return api.get(`widgets/${widgetUuid}/adverts/`, {params: filter})
  }
}
