export default [
  {
    path: '',
    component: () => import('layouts/BackBtnLayout.vue'),
    children: [
      {
        path: '/subscription/business/',
        component: () => import('src/apps/core/pages/BusinessSubscriptionPlans.vue'),
        name: 'business-subscription',
      },
    ]
  },
]
