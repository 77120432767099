import {initializeApp} from 'firebase/app';
import {boot} from 'quasar/wrappers';

let firebase

export default boot(() => {
  // Your web app's Firebase configuration
  // Initialize Firebase
  firebase = initializeApp({
    apiKey: 'AIzaSyDB_7G_1sYAFDlMj9V-8YpAcZk7LufddtA',
    authDomain: 'campertrader-app.firebaseapp.com',
    projectId: 'campertrader-app',
    storageBucket: 'campertrader-app.appspot.com',
    messagingSenderId: '128613646446',
    appId: '1:128613646446:web:68e554b815a27806a8a67f'
  })

});


export {firebase}
