export default [
  {
    path: '',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/tools/ToolsPage.vue'),
        children: [
          {
            path: '/tools/batterie-solar-stromrechner/',
            component: () => import('src/components/tools/BatterySolarCalcTool.vue'),
            name: 'tools-bat-solar-calc',
          }
        ]
      },
    ],
  },

]
