export default [
  {
    path: '',
    component: () => import('layouts/AppMainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/MessengerPage.vue'),
        meta: {loginRequired: true, noFooter: true},
        children: [
          {
            path: '/messenger/:chatUuid?/',
            component: () => import('src/components/messenger/MessengerChat.vue'),
            name: 'messenger-chat',
            meta: {noSiteLoading: true, noFooterBar: true},
          },
          {
            path: '/messenger/',
            component: () => import('src/components/messenger/MessengerChat.vue'),
            name: 'messenger',
          },
        ]
      },
    ],
  },
]
