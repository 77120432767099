import {RouteRecordRaw} from 'vue-router';

const Landing: RouteRecordRaw[] = [
  {
    path: '',
    component: () => import('src/layouts/MainLayout.vue'),
    children: [
      {
        path: '/gebraucht-kaufen/wohnmobil/',
        name: 'wohnmobil-landing-page',
        component: () => import('src/apps/landing/pages/WohnmobilLandingPage.vue'),
      },

      {
        path: '/gebraucht-kaufen/campervan/',
        name: 'campervan-landing-page',
        component: () => import('src/apps/landing/pages/CampervanLandingPage.vue'),
      },
      {
        path: '/gebraucht-kaufen/mini-camper/',
        name: 'mini-camper-landing-page',
        component: () => import('src/apps/landing/pages/MiniCamperLandingPage.vue'),
      },
    ]
  }
]

export default Landing;
