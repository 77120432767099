import script from "./AppStartScreen.vue?vue&type=script&setup=true&lang=ts"
export * from "./AppStartScreen.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QIcon,QBtn});
