import {defineStore} from 'pinia';
import {SearchType} from 'src/apps/search/types';
import {DEFAULT_SEARCH_TYPE, SEARCH_TYPES} from 'src/apps/search/vars';


export const usePersistentLocalStore = defineStore('persistentLocal', {
  state: () => ({
    favoritesTab: 'item' as 'item' | 'advert',
    profileTab: 'item' as 'item' | 'advert',
    language: null as 'de-DE' | 'en-US' | null,
    activeSearchType: null as SearchType | null,
  }),
  actions: {
    updateFavoritesTab(val) {
      this.favoritesTab = val;
    },
    updateProfileTab(val) {
      this.profileTab = val;
    },
    updateLanguage(val) {
      this.language = val;
    },
    updateActiveSearchType(val) {
      this.activeSearchType = val;
    }
  },
  getters: {
    getFavoritesTab: (state) => {
      if (!state.favoritesTab || !['item', 'advert'].includes(state.favoritesTab)) {
        return 'item'
      }
      return state.favoritesTab
    },

    getProfileTab: (state) => {
      if (!state.profileTab || !['item', 'advert'].includes(state.profileTab)) {
        return 'item'
      }
      return state.profileTab
    },

    getLanguage: (state) => {
      if (!state.language || !['de-DE', 'en-US'].includes(state.language)) {
        return 'de-DE'
      }
      return state.language
    },

    getActiveSearchType: (state) => {
      if (!state.activeSearchType || !SEARCH_TYPES.includes(state.activeSearchType)) {
        return DEFAULT_SEARCH_TYPE
      }
      return state.activeSearchType
    }
  },

  persist: {
    enabled: true,
    exclude: [],
    onRestored: (store) => {
      console.debug(`Restored store ${store.$id}`)
    }
  },
})
