export default [
  {
    path: '/extras/widgets/business-adverts/:slug/:widgetUuid/',
    component: () => import('src/apps/widgets/pages/GenericAdvertListPage.vue'),
    name: 'widget-business-adverts',
    meta: {
      disableCookieConsent: true
    }
  },
  {
    path: '/extras/widgets/business-adverts/:slug/',
    component: () => import('pages/widgets/AdvertListWidgets.vue'),
    name: 'widget-business-adverts-legacy',
    meta: {
      disableCookieConsent: true
    }
  },
  {
    path: '/extras/widgets/adverts/:advertUuid/',
    component: () => import('pages/widgets/AdvertWidget.vue'),
    name: 'widget-advert',
    meta: {
      disableCookieConsent: true
    }
  },
  {
    path: '/extras/widgets/advert-list/:widgetUuid/',
    component: () => import('src/apps/widgets/pages/GenericAdvertListPage.vue'),
    name: 'widget-advert-list',
    meta: {
      disableCookieConsent: true
    }
  },
]
