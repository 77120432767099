import {api} from 'src/boot/axios';

export default {
  subscriptionCreate: function (planId) {
    return api.post('payment/', {plan_id: planId})
  },

  subscriptionApprove: function (subscriptionId) {
    return api.post('payment/approve/', {subscription_id: subscriptionId})
  },

  getPrice: function (lookupKey) {
    return api.get(`payment/price-list/${lookupKey}`)
  },

  getCommercialProducts: function () {
    return api.get('payment/product-list/commercial/')
  },

  getPersonalProducts: function () {
    return api.get('payment/product-list/personal/')
  },

  checkDiscountPromotionCode: function (code) {
    return api.post('payment/check-coupon-code/', {code})
  },

  getAccountOpenInvoices: function () {
    return api.get('payment/open-invoices/')
  },

  getInvoice: function (clientSecret) {
    return api.get(`payment/checkout/${clientSecret}/`)
  },

  createSetupIntent: function () {
    return api.post('payment/create-setup-intent/',)
  },

  getAccountPaymentMethods: function () {
    return api.get('payment/payment-methods/',)
  },

  attachPaymentMethod: function (paymentMethodId: string) {
    return api.post('payment/payment-methods/attach/', {payment_method_id: paymentMethodId})
  },

  detachPaymentMethod: function (paymentMethodId: string) {
    return api.post('payment/payment-methods/detach/', {payment_method_id: paymentMethodId})
  },

  getPortalUrl(return_url: string | undefined = undefined, isApp = false) {
    return api.post('payment/portal/', {return_url, is_app: isApp})
  },

  createCheckoutSession(priceId, isApp = false) {
    return api.post('payment/create-checkout-session/', {price_id: priceId, is_app: isApp})
  },

  getCheckoutSession(sessionId) {
    return api.post('payment/retrieve-checkout-session/', {session_id: sessionId})
  }

}
