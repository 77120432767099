import {defineStore} from 'pinia';
import Subscription from 'src/api/subscription';
import Payment from 'src/api/payment';

interface CreateSubscriptionData {
  price: string,
  force?: boolean
}

export const useSubscriptionStore = defineStore('subscription', {
  state: () => ({
    accountSubscription: undefined as any | undefined,
    subscriptionsByClientSecret: {},
    accountQuota: {} as any,
    accountPaymentMethods: undefined as Array<any> | undefined,
  }),
  actions: {
    fetchAccountSubscription: function (refresh = false) {
      return Subscription.getSubscription(refresh).then(resp => {
        this.accountSubscription = resp.data
        return resp
      }).catch(err => {
        const {response} = err
        if (response?.status === 404) {
          this.accountSubscription = undefined
          return Promise.resolve()
        }
        return Promise.reject(err)
      })
    },

    fetchSubscriptionByClientSecret: function (clientSecret) {
      return Subscription.getSubscriptionByClientSecret(clientSecret).then(resp => {
        this.subscriptionsByClientSecret[clientSecret] = resp.data
        return resp
      }).catch(err => {
        console.error(err)
        return Promise.reject(err)
      })
    },

    fetchAccountQuota: function () {
      return Subscription.getAccountQuota().then(resp => {
        this.accountQuota = resp.data
        return resp
      })
    },

    cancelSubscription: function (force = false) {
      return Subscription.cancelSubscription(force).then(async resp => {
        if (force) {
          this.accountSubscription = undefined
        } else {
          await this.fetchAccountSubscription(true)
        }
        return resp
      })
    },

    reactivateSubscription: function () {
      return Subscription.reactivateSubscription().then(async () => {
        await this.fetchAccountSubscription(true)
      })
    },

    createSubscription: function (data: CreateSubscriptionData) {
      return Subscription.createSubscription(data).then(resp => {
        this.accountSubscription = resp.data
        return resp
      })
    },

    fetchAccountPaymentMethods: function () {
      return Payment.getAccountPaymentMethods().then(resp => {
        this.accountPaymentMethods = resp.data
        return resp
      })
    },

    updatePaymentMethod: function (paymentMethodId: string) {
      return Subscription.updatePaymentMethod(paymentMethodId).then(resp => {
        this.accountSubscription.subscription.default_payment_method = paymentMethodId
        return resp
      })
    },
  }
})
