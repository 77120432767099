import {api} from 'boot/axios';

export default {
  getAccountDetails: function () {
    return api.get('auth/user/')
  },

  updateAccount(data) {
    return api.put('auth/user/', data)
  },

  patchAccount(data) {
    return api.patch('auth/user/', data)
  },

  getUserInfo(userUuid) {
    return api.get(`user/${userUuid}/`)
  },

  resetPassword(email) {
    return api.post('auth/password/reset/', {email})
  },

  changePassword(data) {
    return api.post('auth/password/change/', data)
  },

  confirmPassword(data) {
    return api.post('auth/password/reset/confirm/', data)
  },

  resendConfirmationEmail(email) {
    return api.post('auth/signup/resend-email/', {email})
  },

  confirmEmail(key: string) {
    return api.post('auth/signup/verify-email/', {key})
  },

  signupUser(data) {
    return api.post('auth/signup/', data)
  },

  getUserNotificationBanner() {
    return api.get('user/notification_banner/')
  },

  postUserNotification(notificationUuid) {
    return api.post('notification-banner/mark-read/', {notification_uuid: notificationUuid})
  },

  deleteAccount() {
    return api.post('auth/delete/', {yes: true})
  },

  createUserLocation(latitude, longitude, accuracy: number | undefined = undefined) {
    return api.post('preferences/set-user-location/', {latitude, longitude, accuracy})
  }
}
