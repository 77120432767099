export default [
  {
    path: '',
    component: () => import('layouts/BackBtnLayout.vue'),
    children: [
      {
        path: '/suchauftrag',
        alias: '/search-agent',
        component: () => import('src/apps/search/pages/SearchAgentPage.vue'),
        name: 'search-agents',
        meta: {loginRequired: true}
      },
    ]
  }
]
